
import React from 'react';
import AppContainer from './app_container.tsx';

function App() {
  return (
    <AppContainer />
  );
}

export default App;